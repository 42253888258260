import React from 'react';

export default function Subscribe() {
  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
            <h2 className="text-white mb-5">If interested, may I contact you?</h2>

            <form className="form-inline d-flex"
                  action="https://getform.io/f/22deccc1-ddc6-40ee-a6f1-3cff9fbbb362"
                  method="POST">
              <input
                type="email"
                className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                id="inputEmail"
                name="emailToContact"
                placeholder="Enter email address..."
              />
              <button type="submit" className="btn btn-primary mx-auto">
                Reach Out
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
