import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import breathworkImage from '../assets/images/breathwork.jpg';
import sustainabilityImage from '../assets/images/sustainability.jpg';
import bgMaster from '../assets/images/bg-masthead.jpg';
import ashleyBW from '../assets/images/ashley-bw.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              About
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section">
      <div className="container">
        <div className='row text-center'>
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">A Message From The Founder</h2>
          </div>
        </div>
        <div className="row">
          <div className='col-lg-4 my-auto'>
            <img className="img-fluid align-middle" src={ashleyBW} alt="" />
          </div>
          <div className="col-lg-8 mx-auto text-left">
            <p className="text-white-50">
            Hi there! My name is Ashley and I am the proud owner of Inspire Growth! 
            <br/><br/>
            I am passionate about empowering fellow humans to love themselves through all parts 
            of their journey through life. After more than a decade of struggling through countless 
            psychiatric diagnoses and medications, I decided to take my fate into my own hands and 
            learn to truly love and care for myself. This radical shift in thinking led me to trust 
            myself  more, and deepen the connection between my mind and body. Eventually these 
            changes lead me to breathwork and yoga.
            <br/><br/>
            After experiencing the incredible benefits to my mental and physical well being from 
            daily yoga and breathwork, I felt called to share what I learned with others. This 
            feeling led me to yoga teacher training with a specialty in trauma informed yoga. I 
            love working one on one or in small groups empowering my clients to start a conversation 
            with their bodies, and to strengthen their inner power and intuition.
            <br/><br/>
            I truly believe that we are our own healers and we all have super powers within us waiting 
            to be utilized. I strive to help others uncover this beauty and power within themselves in 
            whatever way I can.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="therapies" className="therapies-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={bgMaster} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>YOGA</h4>
              <p className="text-black-50 mb-0">
              I completed my yoga teacher training through the Yoga Center Retreat in the Twin Cities area, 
              but since then I have turned to a more specialized approach using trauma informed yoga. 
              Trauma informed yoga uses techniques to help you work through your trauma in your body as 
              well as your mind.  Using these techniques I will help you learn to calm your mind and 
              regulate your physical responses leading to more emotional ease and stability. 
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={breathworkImage} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">BREATHWORK</h4>
                  <p className="mb-0 text-white-50">
                  The term “breathwork” gets thrown around a lot. I am using it to describe the conscious 
                  control of your breath to help positively influence your mental and physical state. 
                  Practicing breathwork is an incredibly powerful way to deepen your connection with your 
                  inner self. It can help you move through trauma, and also support you in times of growth 
                  and change. 
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={sustainabilityImage} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">PERSONAL SUSTAINABILITY</h4>
                  <p className="mb-0 text-white-50">
                  Thought about going "zero waste" or "plastic-free"? Have you ever thought
                  about growing your own food year-round or composting? Perhaps you just want 
                  to have a more harmonious relationship with nature. It may seem daunting but 
                  it can be easy if you follow our motto: "Do what you can, when you can."
                  <br/><br/> 
                  We'll show you how!
                  </p>
                  <p className="mb-0 text-white-50"></p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;

export const Head = ({ location, params, data, pageContext }) => (
  <>
    <title>{config.siteTitle}</title>
    <meta name="description" content={config.siteDescription} />
    <meta
      name="keywords" content={config.siteKeywords}
    />
  </>
)
